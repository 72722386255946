function collapseNavbar() {
  const otherElement = document.getElementsByClassName('navbar-brand');
  otherElement[0].classList.toggle('navbar-brand-mini');
  const element = document.getElementsByClassName("header-fixed");
  if (element[0].classList.contains('brand-minimized')) {
    element[0].classList.remove('brand-minimized', 'sidebar-minimized');
  } else {
    element[0].classList.add('brand-minimized', 'sidebar-minimized');
  }
}
